import * as $ from 'jquery';
import 'jvectormap';
import 'jvectormap/jquery-jvectormap.css';
import './jquery-jvectormap-world-mill.js';
import { debounce } from 'lodash';

$(document).ready(function() {
    const vectorMapInit = () => {
        if ($('#world-map-marker').length > 0) {
            $('#vmap').remove();
            $('#world-map-marker').append(`<div id="vmap" style="height: 490px; position: relative; overflow: hidden; background-color: transparent;"></div>`);

            $('#vmap').vectorMap({
                map: 'world_mill',
                backgroundColor: '#fff',
                borderColor: '#fff',
                borderOpacity: 0.25,
                borderWidth: 0,
                color: '#e6e6e6',
                regionStyle : {
                initial : {
                    fill : '#e4ecef',
                    },
                },

                markerStyle: {
                    initial: {
                        r: 7,
                        'fill': '#fff',
                        'fill-opacity':1,
                        'stroke': '#000',
                        'stroke-width' : 2,
                        'stroke-opacity': 0.4,
                    },
                },

                markers : [{
                    latLng : [21.00, 78.00],
                    name : 'INDIA : 350',
                }, {
                    latLng : [-33.00, 151.00],
                    name : 'Australia : 250',
                }, {
                    latLng : [36.77, -119.41],
                    name : 'USA : 250',
                }, {
                    latLng : [55.37, -3.41],
                    name : 'UK   : 250',
                }, {
                    latLng : [25.20, 55.27],
                    name : 'UAE : 250',
                }],
                
                series: {
                    regions: [{
                        values: {
                            'US': 298,
                            'SA': 200,
                            'AU': 760,
                            'IN': 200,
                            'GB': 120,
                        },
                        scale: ['#03a9f3', '#02a7f1'],
                        normalizeFunction: 'polynomial',
                    }],
                },
                hoverOpacity: null,
                normalizeFunction: 'linear',
                zoomOnScroll: false,
                scaleColors: ['#b6d6ff', '#005ace'],
                selectedColor: '#c9dfaf',
                selectedRegions: [],
                enableZoom: false,
                hoverColor: '#fff',
            });
        }
    };

    vectorMapInit();
    $(window).resize(debounce(vectorMapInit, 150));
});
