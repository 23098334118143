import * as $ from 'jquery';
import 'datatables';

$(document).ready(function() {
	$('#dataTable').DataTable({
		serverSide: true,
		order: [[ 0, "desc" ]],
		ajax: $('#dataTable').attr('data-source'),
		'aoColumnDefs': [{
			'bSortable': false,
			'aTargets': ['nosort']
		}],
		fnRowCallback: function(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
      if (aData[7] < 0 && aData[6] != 'Material ready for dispatch') {
        $('td', nRow).css('background-color', 'Red');
      }
    }
  })
});
